var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { color, width, height, space, display, fontSize, alignItems, themeGet, } from 'styled-system';
import { GridItem } from '../../common/components/Grid';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import RichTextBlock from '../../common/components/RichText';
import { cursor, textDecoration } from '../../common/theme/system-utilities';
var ICON_RESPONSIVE_SIZES = ['60px', '66px', '68px', '74px'];
var ITEM_REGULAR_MODE_WIDTH = [1, 6 / 12];
var ITEM_COMPACT_MODE_WIDTH = [1, 8 / 12, 4 / 12];
var UspGridItem = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
UspGridItem.defaultProps = {
    mb: ['sp_24', null, null, 'sp_32'],
};
var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, height, space, display, alignItems);
ImageContainer.defaultProps = {
    my: 'sp_0',
    mx: 'auto',
    display: 'flex',
    alignItems: 'center',
    width: ICON_RESPONSIVE_SIZES,
    height: ICON_RESPONSIVE_SIZES,
};
var StyledResponsiveLazyImage = styled(ResponsiveLazyImage)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
StyledResponsiveLazyImage.defaultProps = {
    width: 1,
};
var UspBlockText = styled(RichTextBlock)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n\n  p {\n    ", ";\n    ", "\n  }\n\n  a {\n    ", "\n    ", "\n    ", "\n    color: ", ";\n\n    &:active,\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n"], ["\n  ", ";\n\n  p {\n    ", ";\n    ", "\n  }\n\n  a {\n    ", "\n    ", "\n    ", "\n    color: ", ";\n\n    &:active,\n    &:hover,\n    &:focus {\n      color: ", ";\n    }\n  }\n"])), space, fontSize, color, color, cursor, textDecoration, themeGet('sys.neutral.text.default'), themeGet('colors.sys.primary.background.default'));
UspBlockText.defaultProps = {
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_24', null, null, 'lh_28'],
    mt: 'sp_16',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'sys.neutral.text.default',
};
export var UspItem = function (_a) {
    var image = _a.image, textHtml = _a.textHtml, isCompactWidth = _a.isCompactWidth;
    return (React.createElement(UspGridItem, { width: isCompactWidth ? ITEM_COMPACT_MODE_WIDTH : ITEM_REGULAR_MODE_WIDTH },
        React.createElement(ImageContainer, null,
            React.createElement(StyledResponsiveLazyImage, __assign({}, image))),
        React.createElement(UspBlockText, { dangerouslySetInnerHTML: { __html: textHtml } })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
