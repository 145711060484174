import { isClient } from './isClient';
export var captureError = function (message) {
    var _a;
    if (isClient()) {
        (_a = window.Sentry) === null || _a === void 0 ? void 0 : _a.captureMessage(message);
    }
};
export var captureException = function (error, captureContext) {
    var _a;
    if (isClient()) {
        (_a = window.Sentry) === null || _a === void 0 ? void 0 : _a.captureException(error, captureContext);
    }
};
