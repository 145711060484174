var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { color, boxShadow, textAlign, justifyContent, display, borderTop, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { Grid, GridItem } from '../../common/components/Grid';
import Separator from '../../common/components/Separator';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import { UspCtaLink } from './UspCtaLink';
import { UspHeader } from './UspHeader';
import { UspItem } from './UspItem';
import { useTrackingEvents } from './hooks';
var UspTeaserGrid = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, boxShadow, justifyContent, textAlign, display, borderTop);
UspTeaserGrid.defaultProps = {
    textAlign: 'center',
    bg: 'sys.neutral.background.weak',
    color: 'sys.neutral.text.strong',
    boxShadow: ['0 2px 6px 0 rgba(192, 192, 192, 0.5)', 'none'],
    display: 'flex',
    justifyContent: 'center',
    px: ['sp_16', null, 'sp_36', 'sp_48'],
};
var UspTeaserInnerWrapper = styled(GridItem)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
UspTeaserInnerWrapper.defaultProps = __assign(__assign({}, GridItem.defaultProps), { px: 0 });
var UspLayout = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
UspLayout.defaultProps = {
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'flex-start'],
    justifyContent: 'center',
    flexWrap: 'wrap',
};
var CommonUspTeaser = function (_a) {
    var title = _a.title, subtitleHtml = _a.subtitleHtml, items = _a.items, ctaLinkLabel = _a.ctaLinkLabel, ctaLinkHref = _a.ctaLinkHref, backgroundColor = _a.backgroundColor, textColor = _a.textColor, isContentWithReducedWidth = _a.isContentWithReducedWidth, bs = _a.boxShadow;
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var _c = useTrackingEvents({
        linkText: ctaLinkLabel,
        linkUrl: ctaLinkHref,
    }), onView = _c.onView, onLinkClick = _c.onLinkClick;
    useEffect(function () {
        if (isObserved) {
            onView();
        }
    }, [isObserved]);
    var isItemCountDivisibleByThree = items.length % 3 === 0;
    var hasCta = ctaLinkLabel && ctaLinkHref;
    return (React.createElement(React.Fragment, null,
        backgroundColor && React.createElement(Separator, null),
        React.createElement(UspTeaserGrid, { ref: ref, "data-testid": "usp-teaser-container", bg: backgroundColor, color: textColor, boxShadow: bs },
            React.createElement(UspHeader, { title: title, subtitleHtml: subtitleHtml }),
            React.createElement(UspTeaserInnerWrapper, __assign({ "data-testid": "usp-teaser-inner-wrapper" }, (isContentWithReducedWidth && { width: [1, 6 / 8, 8 / 12] })),
                React.createElement(UspLayout, null, items.map(function (props, index) { return (React.createElement(UspItem, __assign({}, props, { key: index, isCompactWidth: isItemCountDivisibleByThree }))); }))),
            hasCta && (React.createElement(UspCtaLink, { href: ctaLinkHref, onClick: onLinkClick }, ctaLinkLabel)))));
};
export default CommonUspTeaser;
var templateObject_1, templateObject_2, templateObject_3;
