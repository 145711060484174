var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getInteger } from '../number';
import { CDN_BASE_URL } from './constants';
import { transformPrismicImageUrl } from './prismic';
var IMAGE_SIZE_REGEX = /(\/original\/)|(\/\d+x\d+\/)/;
var ALTERNATIVE_SIZE_REGEX = /\d+:\d+/;
var OUTPUT_FORMAT_REGEX = /output-format=([^&]+)/;
export var isOriginalImageUrl = function (src) {
    return IMAGE_SIZE_REGEX.exec(src) || ALTERNATIVE_SIZE_REGEX.exec(src);
};
export var hasOutputFormat = function (src) { return OUTPUT_FORMAT_REGEX.exec(src); };
export var replaceOutputFormat = function (src, format) {
    return src.replace(OUTPUT_FORMAT_REGEX, "output-format=".concat(format.toUpperCase()));
};
var removeBackSlashes = function (image) { return image.replace('/', ''); };
export var getImageURLForCDN = function (_a) {
    var service = _a.service, height = _a.height, width = _a.width, imageName = _a.imageName, _b = _a.format, format = _b === void 0 ? 'png' : _b;
    var dimension = "".concat(height, "x").concat(width);
    var formattedImageName = removeBackSlashes(imageName);
    return "".concat(CDN_BASE_URL, "/images/media/catalog/").concat(service, "/").concat(dimension, "/").concat(format, "/").concat(formattedImageName);
};
export var transformImageUrl = function (url, _a) {
    var width = _a.width, format = _a.format;
    return isOriginalImageUrl(url)
        ? transformOriginalImageUrl(url, __assign({ width: width }, (format && { format: format.toLowerCase() })))
        : transformPrismicImageUrl(url, width);
};
export var transformOriginalImageUrl = function (url, _a) {
    var width = _a.width, height = _a.height, format = _a.format;
    if (format) {
        url = getWithReplacedExt(url, format);
    }
    if (IMAGE_SIZE_REGEX.test(url)) {
        return url.replace(IMAGE_SIZE_REGEX, "/".concat(width, "x").concat(height !== null && height !== void 0 ? height : width, "/"));
    }
    return url.replace(ALTERNATIVE_SIZE_REGEX, "".concat(width, ":").concat(height !== null && height !== void 0 ? height : width));
};
var getAllPixelRatioImgSizes = function (url, size) { return ({
    x1: transformOriginalImageUrl(url, { width: size }),
    x2: transformOriginalImageUrl(url, { width: 2 * size }),
    x3: transformOriginalImageUrl(url, { width: 3 * size }),
}); };
export var urlToLazyImageProps = function (url, _a) {
    var _b = _a.thumbnail, thumbnail = _b === void 0 ? 16 : _b, mobile = _a.mobile, tablet = _a.tablet, desktop = _a.desktop, large_desktop = _a.large_desktop;
    return ({
        thumbnail: transformOriginalImageUrl(url, { width: thumbnail }),
        mobile: getAllPixelRatioImgSizes(url, mobile),
        tablet: getAllPixelRatioImgSizes(url, tablet),
        desktop: getAllPixelRatioImgSizes(url, desktop),
        large_desktop: getAllPixelRatioImgSizes(url, large_desktop),
    });
};
export var getDimensions = function (url) {
    var matches = /(?:w{1}\={1}|\/{1})(\d+)(?:\&h=|x)(\d+)\/?/.exec(url);
    if (!matches) {
        return null;
    }
    return { width: getInteger(matches[1]), height: getInteger(matches[2]) };
};
var getWithReplacedExt = function (src, format) {
    if (hasOutputFormat(src)) {
        return replaceOutputFormat(src, format);
    }
    return src.slice(0, src.lastIndexOf('.') + 1) + format;
};
