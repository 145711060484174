var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { space, fontSize } from 'styled-system';
import { GridItem } from '../../common/components/Grid';
import RichTextBlock from '../../common/components/RichText';
import { Heading } from '../../common/components/Text';
var UspHeaderGridItem = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
UspHeaderGridItem.defaultProps = __assign(__assign({}, GridItem.defaultProps), { mt: 'sp_32', mb: 'sp_24' });
var Title = styled(Heading)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
Title.defaultProps = __assign(__assign({}, Heading.defaultProps), { color: 'inherit', fontSize: ['fs_18', null, null, '26px'], lineHeight: ['lh_28', null, null, '36px'], m: 'sp_0', as: 'h2' });
var Subtitle = styled(RichTextBlock)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n\n  p {\n    ", ";\n  }\n"], ["\n  ", ";\n\n  p {\n    ", ";\n  }\n"])), space, fontSize);
Subtitle.defaultProps = {
    mt: ['sp_16', 'sp_20', null, '22px'],
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_24', null, null, 'lh_28'],
};
export var UspHeader = function (_a) {
    var title = _a.title, subtitleHtml = _a.subtitleHtml;
    return (React.createElement(UspHeaderGridItem, null,
        React.createElement(Title, null, title),
        !!subtitleHtml && (React.createElement(Subtitle, { dangerouslySetInnerHTML: { __html: subtitleHtml } }))));
};
var templateObject_1, templateObject_2, templateObject_3;
