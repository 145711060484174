var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import { lineHeight, fontSize, themeGet } from 'styled-system';
import { linkStyles } from '../Link';
import { Text } from '../Text';
export var font = function (size, height) { return function (props) { return [
    fontSize(__assign(__assign({}, props), { fontSize: size })),
    lineHeight(__assign(__assign({}, props), { lineHeight: height })),
]; }; };
var RichTextBlock = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > * {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  & > :not(:first-of-type):not(br) {\n    margin-top: ", "px;\n  }\n  & > :not(:last-child):not(br) {\n    margin-bottom: ", "px;\n  }\n\n  & a {\n    ", ";\n  }\n\n  & p,\n  pre {\n    ", ";\n  }\n\n  pre {\n    white-space: pre-wrap;\n  }\n\n  & ul,\n  & li {\n    ", ";\n\n    padding-left: ", "px;\n  }\n\n  & h1 {\n    ", ";\n  }\n\n  & h2 {\n    ", ";\n  }\n\n  & h3 {\n    ", ";\n  }\n\n  & h4 {\n    ", ";\n  }\n"], ["\n  & > * {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  & > :not(:first-of-type):not(br) {\n    margin-top: ", "px;\n  }\n  & > :not(:last-child):not(br) {\n    margin-bottom: ", "px;\n  }\n\n  & a {\n    ", ";\n  }\n\n  & p,\n  pre {\n    ", ";\n  }\n\n  pre {\n    white-space: pre-wrap;\n  }\n\n  & ul,\n  & li {\n    ", ";\n\n    padding-left: ", "px;\n  }\n\n  & h1 {\n    ", ";\n  }\n\n  & h2 {\n    ", ";\n  }\n\n  & h3 {\n    ", ";\n  }\n\n  & h4 {\n    ", ";\n  }\n"])), themeGet('space.3'), themeGet('space.3'), linkStyles, font([4, null, null, 5], [3, null, null, '28px']), font([4, null, null, 5], [3, null, null, '28px']), themeGet('space.3'), font([6, null, 7], [4, null, 5]), font(['26px', null, null, '30px'], ['36px', null, null, '40px']), font(['22px', null, null, '26px'], [4, null, null, '36px']), font([5, null, null, '22px'], 4));
RichTextBlock.defaultProps = {
    styled: true,
    textDecoration: 'underline',
};
export default RichTextBlock;
var templateObject_1;
