var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { color, fontSize, fontWeight, lineHeight, space, textAlign, } from 'styled-system';
/** @deprecated Use Typography components */
export var Text = styled.div(fontSize, lineHeight, textAlign, fontWeight, color, space);
Text.defaultProps = {
    color: 'sys.neutral.text.default',
    fontSize: 3,
    lineHeight: 3,
};
/** @deprecated Use Typography components */
export var SmallText = styled.div(fontSize, lineHeight, textAlign, fontWeight, color, space);
SmallText.defaultProps = {
    color: 'sys.neutral.text.default',
    fontSize: 2,
    lineHeight: 1,
};
export var BigHeading = styled.div(fontSize, lineHeight, textAlign, fontWeight, color, space);
/** @deprecated Use Typography components */
BigHeading.defaultProps = {
    color: 'sys.neutral.text.strong',
    textAlign: 'center',
    fontSize: ['fs_24', null, 'fs_32'],
    lineHeight: ['lh_32', null, 'lh_40'],
    fontWeight: 'bold',
};
/** @deprecated Use Typography components */
export var Heading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), fontSize, lineHeight, textAlign, fontWeight, color, space);
Heading.defaultProps = {
    color: 'sys.neutral.text.default',
    fontSize: ['fs_16', null, 'fs_24'],
    lineHeight: ['lh_24', null, 'lh_32'],
    fontWeight: 'bold',
};
/** @deprecated Use Typography components */
export var SmallHeading = styled.div(fontSize, lineHeight, textAlign, fontWeight, color, space);
SmallHeading.defaultProps = {
    color: 'sys.neutral.text.default',
    fontSize: ['fs_14', null, 'fs_16'],
    lineHeight: 'lh_24',
    fontWeight: 'bold',
};
/** @deprecated Use Typography components */
export var SliderTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight, space);
SliderTitle.defaultProps = {
    color: 'sys.neutral.text.active',
    fontSize: ['fs_16', 'fs_18', null, 'fs_20'],
    lineHeight: ['lh_20', 'lh_24', null, 'lh_28'],
    mt: 'sp_0',
};
/** @deprecated Use Typography components */
export var SliderSubtitle = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, color, fontSize, lineHeight, fontWeight);
SliderSubtitle.defaultProps = {
    mt: ['sp_8', null, 'sp_12'],
    mb: ['sp_12', 'sp_20', 'sp_24'],
    color: 'sys.neutral.border.hover',
    fontWeight: 'normal',
    fontSize: ['fs_14', 'fs_16', null, 'fs_18'],
    lineHeight: ['lh_20', 'lh_12', null, 'lh_24'],
};
var templateObject_1, templateObject_2, templateObject_3;
