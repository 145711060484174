var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { border, borderTop, display, height, space } from 'styled-system';
import { borderTopColor, borderTopStyle, borderTopWidth, } from '../theme/system-utilities';
var Separator = styled.hr(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, height, border, borderTopStyle, borderTopWidth, borderTop, borderTopColor, display);
Separator.defaultProps = {
    m: 'sp_0',
    border: 'none',
    borderTopStyle: 'solid',
    borderTopWidth: 'small',
    borderTopColor: 'sys.neutral.border.weak',
    'data-testid': 'separator',
};
export default Separator;
var templateObject_1;
