import { useCallback } from 'react';
import { dispatchTrackingEventGA4 } from '../../common/tracking/dispatchTrackingEventGA4';
export var EventName;
(function (EventName) {
    EventName["CommonUspTeaserView"] = "common-usp-teaser_view";
    EventName["CommonUspTeaserClick"] = "common-usp-teaser_click";
})(EventName || (EventName = {}));
export var useTrackingEvents = function (_a) {
    var linkText = _a.linkText, linkUrl = _a.linkUrl;
    var onView = useCallback(function () {
        dispatchTrackingEventGA4({
            event: EventName.CommonUspTeaserView,
        });
    }, []);
    var onLinkClick = useCallback(function () {
        dispatchTrackingEventGA4({
            event: EventName.CommonUspTeaserClick,
            element: 'link',
            link_text: linkText,
            link_url: linkUrl,
        });
    }, [linkText, linkUrl]);
    return { onView: onView, onLinkClick: onLinkClick };
};
