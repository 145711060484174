var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { color, space } from 'styled-system';
import { GridItem } from '../../common/components/Grid';
import { Link } from '../../common/components/Link';
var LinkGridItem = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
LinkGridItem.defaultProps = __assign(__assign({}, GridItem.defaultProps), { mt: 'sp_16', mb: 'sp_32' });
var LinkWithArrow = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n\n  &::after {\n    content: '\u00A0\u203A';\n  }\n"], ["\n  ", ";\n\n  &::after {\n    content: '\\u00a0\u203A';\n  }\n"])), color);
LinkWithArrow.defaultProps = {
    color: 'inherit',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_24', null, null, 'fs_28'],
};
export var UspCtaLink = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(LinkGridItem, null,
        React.createElement(LinkWithArrow, __assign({}, props), children)));
};
var templateObject_1, templateObject_2;
